import { IconCheck } from '@troon/icons/check';
import { twJoin, twMerge } from '@troon/tailwind-preset/merge';
import { useContext, splitProps, createContext } from 'solid-js';
import type { ParentProps } from 'solid-js';

export function CheckList(props: ParentProps<{ class?: string; color?: 'current' }>) {
	const [ctx] = splitProps(props, ['color']);

	return (
		<Context.Provider value={ctx}>
			<ul class={twMerge('flex flex-col items-stretch gap-1', props.class)}>{props.children}</ul>
		</Context.Provider>
	);
}

export function CheckListItem(props: ParentProps) {
	const ctx = useContext(Context)!;
	return (
		<li class="flex items-baseline justify-stretch gap-1">
			<IconCheck class={twJoin('relative top-1 shrink-0 grow-0 text-xl', ctx.color !== 'current' && 'text-brand')} />
			<span class="block grow">{props.children}</span>
		</li>
	);
}

type CTX = { color?: 'current' };

const Context = createContext<CTX>({});
